// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import { SEO, Title, Text, Intro } from "components";

const pageProperties = {
  title: "Ottieni traffico stabile e duraturo nel tempo con la SEO",
  description:
    "Un sito web funziona se produce vendite o contatti: per questo hai bisogno di traffico. Il traffico che compri con gli annunci e le campagne di ads dura fin tanto che paghi. Il traffico che arriva da altri siti o dai social network ti vincola a questi. Solo i motori di ricerca garantiscono visite nel tempo, realmente interessate ai tuoi contenuti e senza costi aggiuntivi.",
};

function SeoPosizionamentoSuiMotoriDiRicercaPage() {
  return (
    <CleanLayout>
        <SEO
        title="Agenzia SEO: indicizzazione e posizionamento su Google e motori di ricerca "
        description="Ottieni da Google traffico stabile e duraturo nel tempo. Siamo specialisti in servizi SEO. Richiedi ora la tua strategia per scalare i motori di ricerca."
        />

        {/* <div className="w-full md:mt-32" ref={ref}> */}
        <div className="w-screen ">
            <section className="px-8 md:px-28">
            <Intro {...pageProperties} />
            </section>
        </div>

        <section className="intro-page">
        <Title headingLevel="h2">Cos&apos;è la SEO?</Title>
            <div className="flex flex-col items-left px-8 md:px-28 py-16" style={{ 'column-count': 2, 'column-gap': '30px' }} >
              SEO è l’acronimo che sta per Search Engine Optimization. Tradotto letteralmente: “ottimizzazione per i motori di ricerca“.
              Sono l’insieme di strategie e pratiche volte ad aumentare la visibilità di un sito internet migliorandone la posizione nei risultati dei motori di ricerca.
              Una buona posizione sui motori di ricerca non è frutto di una formula magica o di qualche programma speciale: richiede tanto lavoro dentro e fuori al sito ed è influenzata da molti fattori.
              La SEO riguarda diversi aspetti di un sito web: l’ottimizzazione della struttura del sito, del codice HTML, dei contenuti, la gestione dei link interni, di quelli in entrata (detti backlink) ed in uscita.
              Poiché Google è il motore di ricerca di gran lunga più utilizzato al mondo, la maggior parte delle attività SEO riguardano lo studio dell’algoritmo di Google e dei suoi periodici aggiornamenti.
              Lo scopo è rendere il vostro sito più “graditi” a tale algoritmo.
            </div>
            <Title headingLevel="h2">Perchè fare SEO?</Title>
            <div className="flex flex-col items-left px-8 md:px-28 py-16" style={{ 'column-count': 2, 'column-gap': '30px' }} >
              Se ben gestita, la SEO – nonostante il costo – rimane un canale con grande e prolungato ritorno economico. Se non gestita, rischia di rappresentare una minaccia molto forte per la sostenibilità di un business. Basti pensare ad una penalizzazione su Google prolungata per un sito di eCommerce o per un’azienda esclusivamente digitale.
              <br/>
              Una buona attività di SEO può portare benefici duraturi nel tempo, che possono garantire visibilità e traffico per molti anni.
              Le aziende che hanno già risultati organici ottimali invece dovrebbero considerare la SEO come una assicurazione per difendere e proteggere il proprio posizionamento da concorrenza e cambiamenti di Google.
              Intervenire per recuperare posizioni perse è molto più costoso di una corretta manutenzione.  
            </div>
            <div className="row">
              <h2 className="centered-text">Sei interessato? Ecco cosa faremo per te</h2>
              <div className="large-12 columns">
              Chi ti vende una posizione su Google o un canone per mantenerla ti sta truffando.
              <br/>Il lavoro di un SEO specialist si divide in quattro parti e permetterà al tuo sito web o ecommerce di comparire nel posto giusto e al momento giusto,così da incrementare visite, engagement e vendite.
              </div>
            </div>
            <div>
            <h4>1. Audit SEO e strategia</h4>
            <p>Il primo step della consulenza SEO consiste nell’analisi del rendimento attuale del sito web e dei principali competitors, mediante la valutazione dei dati forniti da Google Analytics, Google Search Console e dai nostri tools SEO, andando a produrre un report della situazione attuale.
            Si procede successivamente con una definizione delle keyword avanzata, allo scopo di definire le parole chiave più rilevanti per il tuo business, e alla definizione di una strategia da condividere.</p>
            <h4>2. Analisi on site</h4>
            <p>Una volta definita la strategia migliore per la tua attività, si passa all’ottimizzazione del sito web che, partendo da determinate keywords, include sia l’indicazione interventi tecnici sul codice del sito stesso, sia riorganizzazione della struttura del sito, dei menu e dei link interni, sia la definizione di nuovi contenuti in ottica SEO.
            </p>
            <h4>3. Analisi off site</h4>
            <p>Di fondamentale importanza è l’acquisizione di link esterni che puntano al tuo sito web. I nostri SEO Specialist individuano siti, blog e portali rilevanti e in topic, al fine di realizzare campagne di link building che contribuiscano ad aumentare l’autorevolezza, il trust e il posizionamento del tuo sito web.
            </p>
            <h4>4. Report SEO
            </h4>
            <p>Per monitorare le attività SEO intraprese e valutare il raggiungimento degli obiettivi, i nostri specialisti realizzano dei report periodici, mettendo in evidenza l’andamento generale del sito web, il rendimento delle parole chiave di interesse e l’efficacia delle azioni intraprese.
            </p>
            </div>
            <h2>Scegli la nostra esperienza</h2>
            <div className="large-12 columns">
              Il nostro team è composto sia da specialisti di web marketing che programmatori. Questo ti garantirà soluzioni dinamiche e al passo coi tempi, adeguate alla tua piattaforma on-line, qualunque essa sia.
              <br/>
              <br/>L’esperienza maturata nel settore ci permette di affrontare situazioni complesse e strutturate, di collaborare e affiancare i vostri team IT e di aiutarvi nella progettazione SEO dei vostri nuovi progetti.
              <br/>Per approfondire vi rimandiamo ad articoli su <a href="/seo/successo-migrazione-redirect-seo">particolari casi di studio SEO che abbiamo affrontato</a>.
            </div>

            <div className="row">
              <h2 className="centered-text">Qualcosa in più sul SEO...</h2>
              <div className="medium-6 columns">
              <div className="fadein mod modIconText appear" data-delay="0">
              <div className="icon-text-simple"> <i className="fa fa-search"></i>
              <h3>SEM</h3>
              <p>
              SEM sta per Search Engine Marketing (marketing attraverso i motori di ricerca) ed è la disciplina più ampia che incorpora la SEO. Il SEM comprende sia i risultati di ricerca a pagamento (in cui è possibile comparire utilizzando strumenti come Google Adwords o Bing Ads, precedentemente noto come Microsoft adCenter) che i risultati organici di ricerca (SEO).
              <br/>Una strategia SEM completa utilizza sia la pubblicità a pagamento che l’implementazione di tecniche SEO. Si utilizzano quindi pubblicità pay per click, ovvero l’advertising (inserzioni pubblicitarie sui motori di ricerca).
              </p>
              </div>
              <div className="two spacing"></div>
              </div>
              <div className="fadein mod modIconText appear" data-delay="0">
              <div className="icon-text-simple"> <i className="fa fa-search"></i>
              <h3>Contenuti</h3>
              <p>
              Quando fai una ricerca su Google, ti aspetti di trovare per primi i risultati migliori, cioè quelli più pertinenti.
              Per esempio, se cerchi “erboristeria a Firenze”, non vuoi trovare il sito di un fioraio a Napoli. E vorresti anche avere molte informazioni su quella erboristeria, tipo che prodotti vendono, quanto costano, dove si trova, ecc.
              Fare SEO significa perciò scrivere contenuti ricchi e pertinenti con le parole con cui vogliamo farci trovare.
              </p>
              </div>
              <div className="two spacing"></div>
              </div>
              </div>
              <div className="medium-6 columns">
              <div className="fadein mod modIconText appear" data-delay="0">
              <div className="icon-text-simple"> <i className="fa fa-search"></i>
              <h3>Link building</h3>
              <p>
              I link esterni sono le “strade” del web, cioè i percorsi che usiamo per spostarci da un sito all’altro. Un sito con molti link che portano a lui, è come un incrocio molto trafficato, quindi molto importante.
              <br/><br/>Più il sito che ci linka è importante e simile al nostro nei contenuti, più comoda sarà la strada che conduce a noi. Per questo, fare SEO significa anche cercare di ottenere un buon numero di link da siti il più possibile pertinenti e rilevanti.
              </p>
              </div>
              <div className="two spacing"></div>
              </div>
              <div className="fadein mod modIconText appear" data-delay="0">
              <div className="icon-text-simple"> <i className="fa fa-search"></i>
              <h3>Struttura e progettazione</h3>
              <p>
              La struttura del sito è determinante sia per la SEO che per l’usabilità.
              <br/>I menu, i link interni, la raggiungibilità delle pagine e la struttura delle url sono fattori cruciali.
              <br/>Su questi aspetti non bisognerebbe mai improvvisare.
              <br/>Questo però non significa che un sito non possa essere corretto in corso d’opera. Su molti
              </p>
              </div>
              <div className="two spacing"></div>
              </div>
              </div>
              
            </div>
        </section>

    </CleanLayout>
  );
}

export default SeoPosizionamentoSuiMotoriDiRicercaPage;
